// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
/* eslint-disable */
import Bubble from '~/packages/spa-ec/src/displayComponents/Bubble/Bubble.theme-patroner.js';
import CartMod from '~/packages/spa-ec/src/components/Cart/CartMod.theme-patroner.js';
import CartTable from '~/packages/spa-ec/src/displayComponents/CartTable/CartTable.theme-patroner.js';
import Cheapest from '~/packages/spa-ec/src/displayComponents/PrisjaktCheapest/Cheapest.theme-patroner.js';
import ContentPage from '~/packages/spa-ec/src/routes/ContentPage/ContentPage.theme-patroner.js';
import Footer from '~/packages/spa-ec/src/components/Footer/Footer.theme-patroner.js';
import Login from '~/packages/spa-ec/src/components/Login/Login.theme-patroner.js';
import ModelFinder from '~/packages/spa-ec/src/components/ModelFinder/ModelFinder.theme-patroner.js';
import ModelShelf from '~/packages/spa-ec/src/components/ModelShelf/ModelShelf.theme-patroner.js';
import MyPages from '~/packages/spa-ec/src/components/MyPages/MyPages.theme-patroner.js';
import OneBuyPanel from '~/packages/spa-ec/src/components/ProductDetails/OneBuyPanel/themes/OneBuyPanel.theme-patroner.js';
import OrderConfirmation from '~/packages/spa-ec/src/components/OrderConfirmation/OrderConfirmation.theme-patroner.js';
import PageTitle from '~/packages/spa-ec/src/components/StaticPageComponents/PageTitle.theme-patroner.js';
import PasswordRequest from '~/packages/spa-ec/src/components/PasswordRequest/PasswordRequest.theme-patroner.js';
import ProductDetails from '~/packages/spa-ec/src/components/ProductDetails/themes/ProductDetails.theme-patroner.js';
import ProductRowMod from '~/packages/spa-ec/src/components/ModelShelf/ProductRowMod.theme-patroner.js';
import QuantityCounter from '~/packages/spa-ec/src/components/QuantityCounter/QuantityCounter.theme-patroner.js';
import ShelfSection from '~/packages/spa-ec/src/components/ModelShelf/ShelfSection.theme-patroner.js';
import SiteLogo from '~/packages/spa-ec/src/components/Header/HeaderSiteLogo/SiteLogo.theme-patroner.js';
import USPWrapper from '~/packages/spa-ec/src/displayComponents/Cms/USPWrapper.theme-patroner.js';

const overrides = {
  Bubble,
  CartMod,
  CartTable,
  Cheapest,
  ContentPage,
  Footer,
  Login,
  ModelFinder,
  ModelShelf,
  MyPages,
  OneBuyPanel,
  OrderConfirmation,
  PageTitle,
  PasswordRequest,
  ProductDetails,
  ProductRowMod,
  QuantityCounter,
  ShelfSection,
  SiteLogo,
  USPWrapper,
};
export default overrides;

// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
